// src/Home.js
import React from 'react';

const Home = () => {
  return (
    <main className="content">
      <section className="intro">
        <h1>Welcome to Trovare AI</h1>
        <p>
          At Trovare AI, we consult, design, and implement custom AI solutions for small and mid-sized businesses. Contact us today to discover how our AI expertise can elevate your business.
        </p>
      </section>
      <section className="services">
        <div className="service-card">
          <h3>What We Do</h3>
          <p>
            We consult, design, and implement AI-driven solutions for small and mid-sized businesses. Contact us today to see how our AI technology can transform your business.
          </p>
        </div>
        <div className="service-card">
          <h3>AI-Powered Mobile Applications</h3>
          <p>
            We excel in integrating AI into mobile applications. Whether you need an iOS, Android, or cross-platform Flutter app with advanced AI features, we can help bring your vision to life.
          </p>
        </div>
        <div className="service-card">
          <h3>AI-Enhanced Game Development</h3>
          <p>
            Whether you're an independent game developer or part of a larger studio, we can enhance your games with AI-driven features. If you need support to meet a rollout deadline, we're here to help.
          </p>
        </div>
        <div className="service-card">
          <h3>Prototyping AI Solutions</h3>
          <p>
            Communicating AI concepts can be challenging. There's no better way to convey your ideas than with a working AI prototype. We can help you develop and refine your AI concepts.
          </p>
        </div>
        <div className="service-card">
          <h3>AI Consulting</h3>
          <p>
            Looking to integrate AI into your business? Getting expert insight during the planning stages can save significant time and money. Let us help you plan and implement your AI strategy.
          </p>
        </div>
        <div className="service-card">
          <h3>AI-Driven Business Automation</h3>
          <p>
            Let us help you reduce costs by automating core functions of your business with AI. We can identify and automate repetitive tasks, freeing up your staff to focus on more valuable work.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Home;
