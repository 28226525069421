// src/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <h1>About Trovare AI</h1>
        <p>Welcome to Trovare AI, your trusted partner in cutting-edge AI solutions. Our mission is to consult, design, and implement custom AI systems for small and mid-sized businesses, empowering them to harness the full potential of artificial intelligence in the digital age.</p>

        <h2>Who We Are</h2>
        <p>At Trovare AI, we are a team of passionate AI developers, data scientists, and consultants dedicated to delivering high-quality AI solutions tailored to meet your business needs. Our expertise spans a wide range of AI technologies and industries, allowing us to provide versatile and effective solutions.</p>

        <h2>Our Services</h2>
        <ul>
          <li><strong>Consulting:</strong> We offer expert consulting services to help you identify and implement the best AI solutions for your business.</li>
          <li><strong>Custom AI Development:</strong> Our team designs and builds custom AI models and systems tailored to your specific requirements, ensuring scalability and efficiency.</li>
          <li><strong>Mobile AI Integration:</strong> We develop engaging and intuitive mobile applications that incorporate powerful AI features for iOS, Android, and cross-platform Flutter solutions.</li>
          <li><strong>AI-Powered Game Development:</strong> Our game development team enhances your gaming experiences with AI-driven features, from intelligent NPCs to adaptive gameplay mechanics.</li>
          <li><strong>Prototyping AI Solutions:</strong> We create AI prototypes to help you visualize and refine your AI concepts before full-scale development.</li>
          <li><strong>Business Automation with AI:</strong> We streamline your business processes by developing AI-driven automation solutions that save time and reduce costs.</li>
        </ul>

        <h2>Why Choose Us?</h2>
        <p>Choosing Trovare AI means partnering with a team committed to your success. We specialize in deploying custom-trained AI models that are fine-tuned to your business’s unique needs. Our client-centric approach ensures we work closely with you to understand your challenges and goals, delivering solutions that provide real value.</p>

        <h2>Get In Touch</h2>
        <p>Ready to elevate your business with custom AI solutions? Contact us today to learn more about how we can help you achieve your goals with cutting-edge AI technology.</p>
      </div>
    </div>
  );
};

export default About;

