// src/Privacy.js
import React from 'react';

const Privacy = () => {
  return (
    <main className="content">
      <section className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          At Trovare AI, we value your privacy and are committed to protecting your personal data. We do not use cookies or collect any personal information without your explicit consent.
        </p>
        <p>
          For our mobile applications, we adhere to strict privacy guidelines to ensure that your data is handled with the utmost care and security.
        </p>
        <h2>Data Collection</h2>
        <p>
          We collect only the data necessary to provide and improve our services. This includes usage data, device information, and any information you provide directly to us.
        </p>
        <h2>Data Use</h2>
        <p>
          The data we collect is used solely for the purpose of enhancing our services and providing you with the best possible experience.
        </p>
        <h2>Data Sharing</h2>
        <p>
          We do not share your personal data with third parties without your consent, except as required by law or to protect our rights.
        </p>
        <h2>Custom Solutions and Information Sharing</h2>
        <p>
          The custom AI solutions we develop for our clients may involve varying degrees of information sharing with our partners. However, we assure you that no data is shared without your explicit consent. We work closely with our clients to ensure that any information sharing is transparent, secure, and in line with your privacy preferences.
        </p>
        <p>
          Our commitment is to maintain the highest standards of data privacy and security, giving you full control over how your information is used and shared.
        </p>
      </section>
    </main>
  );
};

export default Privacy;

